const baseURL = "/v1/ParametrizacaoComprovacao";

const service = {
  methods: {
    $listarAnoSemestreParametrizacao() {
      return this.$api().get(`${baseURL}/ListarAnoSemestre`);
    },
    $obterParametrizacaoComprovacao() {
      return this.$api().get(`${baseURL}`);
    },
    $cadastrarParametrizacaoComprovacao(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarParametrizacaoComprovacao(body, id) {
      return this.$api().put(`${baseURL}/${id}`, body);
    },
    $buscarDadosParametrizacaoComprovacao(id) {
      return this.$api().get(`${baseURL}/${id}`);
    },
    $inativarParametrizacaoComprovacao(body, id) {
      return this.$api().patch(`${baseURL}/${id}`, body);
    }
  },
};

export default service;
