const baseURL = "/v1/Administrador";

const service = {
  methods: {
    $obterRelatorioPagamento(params) {
      return this.$api().get(`${baseURL}/Relatorio`, params);
    },
    $obterRelatorioCSVPagamento(params) {
      return this.$api().get(`${baseURL}/RelatorioCsv`, params);
    },
    $obterRelatorioValidacao(params) {
      return this.$api().get(`${baseURL}/RelatorioValidacaoComprovacao`, params);
    },
    $obterRelatorioCSVValidacao(params) {
      return this.$api().get(`${baseURL}/RelatorioValidacaoComprovacaoCsv`, params);
    },
    $obterRelatorioValidacaoSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioValidacaoComprovacaoSintetico`, params);
    },
    $obterRelatorioCSVValidacaoSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioValidacaoComprovacaoSinteticoCsv`, params);
    },
    $obterRelatorioAcaoDeCapacitacao(params) {
      return this.$api().get(`${baseURL}/RelatorioAcaoCapacitacao`, params);
    },
    $obterRelatorioCSVAcaoDeCapacitacao(params) {
      return this.$api().get(`${baseURL}/RelatorioAcaoCapacitacaoCsv`, params);
    },
    $obterRelatorioAcaoDeCapacitacaoSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioAcaoCapacitacaoSintetico`, params);
    },
    $obterRelatorioCSVAcaoDeCapacitacaoSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioAcaoCapacitacaoSinteticoCsv`, params);
    },
    $obterRelatorioProfissionalSemBeneficio(params) {
      return this.$api().get(`${baseURL}/RelatorioProfissionalSemBeneficio`, params);
    },
    $obterRelatorioCSVProfissionalSemBeneficio(params) {
      return this.$api().get(`${baseURL}/RelatorioProfissionalSemBeneficioCsv`, params);
    },
    $obterRelatorioProfissionalSemBeneficioSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioProfissionalSemBeneficioSintetico`, params);
    },
    $obterRelatorioCSVProfissionalSemBeneficioSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioProfissionalSemBeneficioSinteticoCsv`, params);
    },
    $obterRelatorioUsuarios(params) {
      return this.$api().get(`${baseURL}/RelatorioUsuarios`, params);
    },
    $obterRelatorioCSVUsuarios(params) {
      return this.$api().get(`${baseURL}/RelatorioUsuariosCsv`, params);
    },
    $obterRelatorioUsuariosSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioUsuariosSintetico`, params);
    },
    $obterRelatorioCSVUsuariosSintetico(params) {
      return this.$api().get(`${baseURL}/RelatorioUsuariosSinteticoCsv`, params);
    },
    $obterRelatorioQuantitativoPorCiclo(params) {
      return this.$api().get(`${baseURL}/RelatorioQuantitativoPorCiclo`, params);
    },
    $obterRelatorioQuantitativoPorCicloCsv(params) {
      return this.$api().get(`${baseURL}/RelatorioQuantitativoPorCicloCsv`, params);
    },
    $obterRelatorioLogUsuario(params) {
      return this.$api().get(`${baseURL}/RelatorioLogUsuario`, params);
    },
    $obterRelatorioLogUsuarioCsv(params) {
      return this.$api().get(`${baseURL}/RelatorioLogUsuarioCsv`, params);
    },
    $obterRelatorioLogAcaoDeCapacitacao(params) {
      return this.$api().get(`${baseURL}/RelatorioLogAcaoCapacitacao`, params);
    },
    $obterRelatorioCSVLogAcaoDeCapacitacao(params) {
      return this.$api().get(`${baseURL}/RelatorioLogAcaoCapacitacaoCsv`, params);
    },
    $obterRelatorioLogProfissional(params) {
      return this.$api().get(`${baseURL}/RelatorioLogProfissional`, params);
    },
    $obterRelatorioCSVLogProfissional(params) {
      return this.$api().get(`${baseURL}/RelatorioLogProfissionalCsv`, params);
    },
    $obterRelatoriosLogValidacaoComprovacao(params) {
      return this.$api().get(`${baseURL}/RelatorioLogValidacaoComprovacaoCapacitacao`, params);
    },
    $obterRelatorioCSVLogValidacaoComprovacao(params) {
      return this.$api().get(`${baseURL}/RelatoriosCSVLogValidacaoComprovacaoCapacitacao`, params);
    },
    $obterRelatorioLogConfiguracaoEdicao(params) {
      return this.$api().get(`${baseURL}/RelatorioLogConfiguracaoEdicao`, params);
    },
    $obterRelatorioCSVLogConfiguracaoEdicao(params) {
      return this.$api().get(`${baseURL}/RelatorioLogConfiguracaoEdicaoCsv`, params);
    },
    $obterRelatorioLogFaq(params) {
      return this.$api().get(`${baseURL}/RelatorioLogFaq`, params);
    },
    $obterRelatorioLogFaqCsv(params) {
      return this.$api().get(`${baseURL}/RelatorioLogFaqCsv`, params);
    },
    $obterRelatorioLogTermo(params) {
      return this.$api().get(`${baseURL}/RelatorioLogTermo`, params);
    },
    $obterRelatorioLogTermoCsv(params) {
      return this.$api().get(`${baseURL}/RelatorioLogTermoCsv`, params);
    },
    $obterRelatorioPdf(params) {
      return this.$api().get(`${baseURL}/DownloadRelatorio`, params);
    },
  },
};

export default service;