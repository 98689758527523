const baseURL = "/v1/Termo";

const service = {
  methods: {
    $buscarTermos(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $visualizarTermo({ params, id }) {
      return this.$api().get(`${baseURL}/${id}`, params);
    },
    $cadastrarTermo(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarTermo(body, id) {
      return this.$api().put(`${baseURL}/${id}`, body);
    },
    $inativarAtivarTermo(body, id) {
      return this.$api().patch(`${baseURL}/${id}`, body);
    },
    $listarTiposDeTermo(params) {
      return this.$api().get(`${baseURL}/TermoTipo`, params);
    }
  },
};

export default service;
