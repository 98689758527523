const baseURL = "/v1/ManterCiclos";

const service = {
  methods: {
    $buscarCiclo(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $buscarDadosCiclo({ params, id }) {
      return this.$api().get(`${baseURL}/${id}`, params);
    },
    $cadastrarCiclo(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarCiclo(body, id) {
      return this.$api().put(`${baseURL}/${id}`, body);
    },
    $inativarAtivarCiclo(body, id) {
      return this.$api().patch(`${baseURL}/${id}`, body);
    }
  },
};

export default service;
