import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import axios from "axios";

import configurations from "commons/configurations";

export const AUTH_NAMESPACE = "auth";

// mutations (privadas)
const MUTATE_TOKEN = "mutateToken";
const MUTATE_USER = "mutateUser";
const MUTATE_MENU = "mutateMenu";
const MUTATE_ABILITIES = "mutateAbilities";

// actions (publicas)
export const LOGIN = "login";
export const LOGOUT = "sair";
export const REFRESH_TOKEN = "refreshToken";
export const AUTO_REFRESH_TOKEN = "autoRefreshToken";
export const UPDATE_MENU = "updateMenu";
export const UPDATE_ABILITIES = "updateAbilities";
export const CLEAR = "clear";

// getters (publicos)
export const TOKEN = "see.avp.token";
export const USER = "user";
export const MENU = "menu";
export const ABILITIES = "abilities";

export default {
  namespaced: true,
  state: () => ({
    token: localStorage.getItem(TOKEN) || "",
    user: JSON.parse(atob(localStorage.getItem(USER) || "") || null),
    menu: JSON.parse(atob(localStorage.getItem(MENU) || "") || null),
    abilities: JSON.parse(atob(localStorage.getItem(ABILITIES) || "") || null),
  }),
  mutations: {
    [MUTATE_TOKEN](state, token) {
      state.token = token;
      localStorage.setItem(TOKEN, token || "");
    },
    [MUTATE_USER](state, user) {
      state.user = user;
      localStorage.setItem(USER, btoa(JSON.stringify(user)));
    },
    [MUTATE_MENU](state, menu) {
      state.menu = menu;
      localStorage.setItem(MENU, btoa(JSON.stringify(menu)));
    },
    [MUTATE_ABILITIES](state, abilities) {
      state.abilities = abilities;
      localStorage.setItem(ABILITIES, btoa(JSON.stringify(abilities)));
    },
  },
  actions: {
    async [LOGOUT]({ commit }) {
      commit(MUTATE_TOKEN, undefined);
      commit(MUTATE_USER, null); // null para usar com JSON.parse
      commit(MUTATE_MENU, null); // null para usar com JSON.parse
      commit(MUTATE_ABILITIES, null); // null para usar com JSON.parse
    },
    async [LOGIN]({ commit, dispatch }, data) {
      const valid = data && data.token;

      if (valid) {
        commit(MUTATE_TOKEN, data.token);
        commit(MUTATE_USER, {
          username: data.nomeDeUsuario,
          firstName: data.primeiroNome,
          lastName: data.sobrenome,
          fullName: data.nomeCompleto,
          email: data.email,
          roles: data.perfis,
        });

        dispatch(AUTO_REFRESH_TOKEN);
      } else {
        dispatch(LOGOUT);
      }
    },
    async [REFRESH_TOKEN]({ state, dispatch }) {
      if (!state.token) {
        throw new Error("Usuário não logado");
      }

      axios
        .create({
          baseURL: configurations.api.baseUri,
          headers: {
            Authorization: `Bearer ${state.token}`
          }
        })
        .get("/v1/Acesso/RenovarToken")
        .then((response) => {
          dispatch(LOGIN, response.data || {});
        })
        .catch(() => {
          dispatch(LOGOUT)
        });
    },
    async [UPDATE_MENU]({ commit }, data) {
      commit(MUTATE_MENU, data || null); // null para usar com JSON.parse
    },
    async [UPDATE_ABILITIES]({ commit }, data) {
      commit(MUTATE_ABILITIES, data || null); // null para usar com JSON.parse
    },
    async [AUTO_REFRESH_TOKEN]({ state, dispatch }) {
      if (!state.token) {
        throw new Error("Usuário não logado");
      }

      const { exp } = jwtDecode(state.token);
      const expiration = dayjs(new Date(exp * 1000));
      const fifteenMinutesBefore =
        expiration.valueOf() - expiration.subtract(15, "minute").valueOf();

      setTimeout(() => dispatch(REFRESH_TOKEN), fifteenMinutesBefore); // atualiza 15 minutos antes de expirar
    },
    async [CLEAR]({ dispatch }) {
      dispatch(LOGOUT);
    },
  },
  getters: {
    [TOKEN]: (state) => state.token,
    [USER]: (state) => state.user,
    [MENU]: (state) => state.menu,
    [ABILITIES]: (state) => state.abilities,
  },
};
