var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Editor', {
    attrs: {
      "readonly": _vm.disabled,
      "required": _vm.required
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }