var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "100%"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('v-card', {
    staticClass: "not-margin pt-0 pr-0 pl-0"
  }, [_c('v-toolbar', {
    attrs: {
      "dark": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.label))])]), _c('div', {
    staticClass: "container dialog"
  }, [_c('v-form', {
    ref: "form"
  }, [_c('v-row', {
    staticClass: "d-flex justify-center mt-7"
  })], 1), _c('v-row', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.previewUrl ? _c('iframe', {
    staticStyle: {
      "border": "1px solid #666ccc"
    },
    attrs: {
      "id": "framePreview",
      "title": "PDF in an i-Frame",
      "src": _vm.previewUrl,
      "frameborder": "1",
      "scrolling": "auto",
      "width": "100%",
      "height": "600px"
    }
  }) : _c('p', [_vm._v(" Não foi possível carregar a pré-visualização do anexo ")])])], 1), _c('v-row', {
    staticClass: "text-center"
  }, [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "download",
      rawName: "v-download",
      value: _vm.path,
      expression: "path"
    }],
    attrs: {
      "color": "primary",
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(`${_vm.descricao}`) + " ")])], 1)], 1)], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v("Fechar")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }