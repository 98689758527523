import Vue from "vue";
import Vuex from "vuex";
import Auth, { AUTH_NAMESPACE } from "./modules/auth";
import PreLoginLayout, { PRE_LOGIN_LAYOUT_NAMESPACE } from "./modules/pre-login-layout";

export const CLEAR = "clear";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    async [CLEAR]({ dispatch }) {
      dispatch(`${AUTH_NAMESPACE}/${CLEAR}`);
      dispatch(`${PRE_LOGIN_LAYOUT_NAMESPACE}/${CLEAR}`);
    },
  },
  modules: {
    [AUTH_NAMESPACE]: Auth,
    [PRE_LOGIN_LAYOUT_NAMESPACE]: PreLoginLayout,
  },
});
