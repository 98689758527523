const directive = (el, binding, vnode) => {
  const download = () => {
    if (binding.value) {
      const vueInstance = vnode.context.$root;
      vueInstance
        .$realizarDownloadPorPath({
          params: {
            path: binding.value,
            nomeDeUsuario: vueInstance.$user.username,
          },
          responseType: "blob",
        })
        .then(({ data }) => {
          const url = URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", binding.value);
          document.body.appendChild(link);
          link.click();
          link.remove();
          vueInstance.$notifySuccess("Download Realizado com Sucesso!");
        })
    }
  }
  el.addEventListener("click", download);
};

export default directive;
