const baseURL = "/v1/Perfil";

const service = {
  methods: {
    $buscarPerfis(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $visualizarPerfil({ params, id }) {
      return this.$api().get(`${baseURL}/${id}`, params);
    },
    $cadastrarPerfil(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarPerfil(body, id) {
      return this.$api().put(`${baseURL}/${id}`, body);
    },
    $inativarAtivarPerfil(body, id) {
      return this.$api().patch(`${baseURL}/${id}`, body);
    }
  },
};

export default service;
