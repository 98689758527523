<template>
  <Editor
    v-model="value"
    :readonly="disabled"
    :required="required"
  ></Editor>
</template>

<script>
import Editor from 'primevue/editor';

export default {
  components: { Editor },
  props: {
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(v) {
      this.$emit("input", v);
    },
  },
};
</script>

