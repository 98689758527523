<template>
  <div v-if="disabled" class="d-flex col" :class="`col-${cols}`">
    <v-text-field
      class="pr-12"
      outlined
      dense
      readonly
      :value="value.ano"
      :label="`Ano ${sufix}`"
    ></v-text-field>
    <v-text-field
      class="pl-12"
      outlined
      dense
      readonly
      :value="value.semestre"
      :label="`Semestre ${sufix}`"
    ></v-text-field>
  </div>
  <div v-else class="d-flex col" :class="`col-${cols}`">
    <v-select
      v-model="value.ano"
      class="pr-12"
      outlined
      dense
      :label="`Ano ${sufix}`"
      :items="form.lists.listAnos"
      :item-text="'ano'"
      :rules="required ? [(v) => $required(v)] : []"
    ></v-select>
    <v-select
      v-model="value.semestre"
      class="pl-12"
      outlined
      dense
      :label="`Semestre ${sufix}`"
      :items="form.lists.listSemestres"
      :item-text="'ano'"
      :rules="required ? [(v) => $required(v)] : []"
      :disabled="value.ano == 'Todos'"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "SelectAnoSemestreComponent",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    // Label a ser mostrado após "Ano" e "Semestre"
    sufix: {
      type: String,
      default: "",
    },
    // Flag para definir se a listagem vai possuir a opção "Todos"
    all: {
      type: Boolean,
      default: false,
    },
    // Flag para definir se o select vai vir setado o ano e semestre ativo
    active: {
      type: Boolean,
      default: true,
    },
    // Flag para definir, se o select vai consultar apenas os anos/semestres ativos
    onlyActive: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 12,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      lists: {
        listAnos: [],
        listSemestres: [],
      },
    },
  }),
  watch: {
    value(v) {
      this.$emit("input", v);
    },
    "value.ano": {
      handler(val) {
        this.form.lists.listSemestres = this.form.lists.listAnos
          .filter((l) => l.ano == val)
          .map((l) => l.semestre)
          .flat();
        if (this.all) this.form.lists.listSemestres.push("Todos");
        if (val == "Todos") this.value.semestre = "Todos";
      },
    },
  },
  mounted() {
    const listar = () => {
      return this.onlyActive
        ? this.$listarAnoSemestreEdicoes({ params: { ativa: this.onlyActive } })
        : this.$listarAnoSemestreEdicoes();
    };
    listar().then(({ data }) => {
      this.form.lists.listAnos = data.anosSemestres;
      if (this.active) {
        const anoSemestreAtivo = this.form.lists.listAnos.find((l) => l.ativo);
        this.value.ano = anoSemestreAtivo.ano;
        this.value.semestre = anoSemestreAtivo.semestre;
        this.form.lists.listSemestres = this.form.lists.listAnos
          .filter((l) => l.ano == this.value.ano && l.ativo)
          .map((l) => l.semestre)
          .flat();
      } else {
        this.value.ano = parseInt(this.value.ano);
        this.value.semestre = parseInt(this.value.semestre);
        this.form.lists.listSemestres = this.form.lists.listAnos
          .filter((l) => l.ano == this.value.ano)
          .map((l) => l.semestre)
          .flat();
      }
      if (this.all) this.form.lists.listAnos.push("Todos");
    });
  },
};
</script>
<style lang="scss" scoped>
div.col {
  .v-input.pr-12 {
    padding-right: 12px !important;
    width: 50%;
  }
  .v-input.pl-12 {
    padding-left: 12px !important;
    width: 50%;
  }
}
</style>
