<template>
  <v-chip :color="ativo ? 'green' : 'red'" outlined>
    {{ ativo ? "Sim" : "Não" }}
  </v-chip>
</template>

<script>
export default {
  name: "AtivoLabelComponent",
  props: {
    ativo: {
      type: Boolean,
      default: false,
    },
  },
};
</script>