const filter = (value) => {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
  return formatter.format(value);
}

export default filter;
