var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('v-expand-x-transition', [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "overlay-color": "white",
      "width": "300",
      "overlay-opacity": _vm.opacity
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c('v-card', {
    staticClass: "text-center pt-3 loader",
    attrs: {
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-card-text', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  }), _c('v-progress-linear', {
    staticClass: "mb-0 mt-1",
    attrs: {
      "indeterminate": _vm.indeterminate,
      "value": _vm.progress,
      "color": "white"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }