const mixin = {
  methods: {
    $setThemeAdmin() {
      let root = document.querySelector(":root");
      root.style.setProperty("--primary-header-color", THEMES.ADMIN.PRIMARY_HEADER);
      root.style.setProperty("--primary-link-color", THEMES.ADMIN.PRIMARY_LINK);
      root.style.setProperty("--primary-button-color", THEMES.ADMIN.PRIMARY_BUTTON);
      root.style.setProperty("--outlined-button-color", THEMES.ADMIN.OUTLINED_BUTTON);
    },
    $setThemeProfissional() {
      let root = document.querySelector(":root");
      root.style.setProperty("--primary-header-color", THEMES.PROFISSIONAL.PRIMARY_HEADER);
      root.style.setProperty("--primary-link-color", THEMES.PROFISSIONAL.PRIMARY_LINK);
      root.style.setProperty("--primary-button-color", THEMES.PROFISSIONAL.PRIMARY_BUTTON);
      root.style.setProperty("--outlined-button-color", THEMES.PROFISSIONAL.OUTLINED_BUTTON);
    },
    $resetTheme() {
      let root = document.querySelector(":root");
      root.style.setProperty("--primary-header-color", THEMES.DEFAULT.PRIMARY_HEADER);
      root.style.setProperty("--primary-link-color", THEMES.DEFAULT.PRIMARY_LINK);
      root.style.setProperty("--primary-button-color", THEMES.DEFAULT.PRIMARY_BUTTON);
      root.style.setProperty("--outlined-button-color", THEMES.DEFAULT.OUTLINED_BUTTON);
    },
  },
};

const THEMES = {
  DEFAULT: {
    PRIMARY_HEADER: "linear-gradient(to right, rgba(13, 71, 161, 1), rgba(48, 129, 255, 1))",
    PRIMARY_LINK: "rgb(47, 143, 204)",
    PRIMARY_BUTTON: "rgb(47, 143, 204)",
    OUTLINED_BUTTON: "rgb(47, 143, 204)",
  },
  PROFISSIONAL: {
    PRIMARY_HEADER: "linear-gradient(to right, rgba(13, 71, 161, 1), rgba(48, 129, 255, 1))",
    PRIMARY_LINK: "rgb(47, 143, 204)",
    PRIMARY_BUTTON: "rgb(47, 143, 204)",
    OUTLINED_BUTTON: "rgb(47, 143, 204)",
  },
  ADMIN: {
    PRIMARY_HEADER: "linear-gradient(to right, rgba(156, 109, 10, 1), rgba(255, 174, 0, 1))",
    PRIMARY_LINK: "rgba(156, 109, 10, 1)",
    PRIMARY_BUTTON: "rgba(156, 109, 10, 1)",
    OUTLINED_BUTTON: "rgba(156, 109, 10, 1)",
  },
};

export default mixin;
