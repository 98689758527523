var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex col",
    class: `col-${_vm.cols}`
  }, [_c('label', {
    class: _vm.className,
    attrs: {
      "tabindex": "0",
      "role": "checkbox"
    }
  }, [_c('input', {
    staticClass: "v-switch-input",
    attrs: {
      "type": "checkbox",
      "tabindex": "-1",
      "disabled": _vm.disabled
    },
    domProps: {
      "checked": _vm.value
    },
    on: {
      "click": function ($event) {
        return _vm.mudar($event);
      }
    }
  }), _c('span', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "v-switch-core",
    style: _vm.coreStyle
  }, [_vm.domRenderizado && _vm.showBaloon ? _c('div', {
    staticClass: "v-switch-button",
    style: _vm.buttonStyle
  }) : _vm._e()]), [_vm.value ? _c('span', {
    staticClass: "v-switch-label v-left",
    style: _vm.labelStyle
  }, [_vm._t("checked", function () {
    return [[_vm._v(_vm._s(_vm.labelChecked))]];
  })], 2) : _c('span', {
    staticClass: "v-switch-label",
    class: _vm.showBaloon ? 'v-right' : ' v-left',
    style: _vm.labelStyle
  }, [_vm._t("unchecked", function () {
    return [[_vm._v(_vm._s(_vm.labelUnchecked))]];
  })], 2)]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }