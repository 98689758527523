var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "text-center"
  }, [_c('v-col', {
    staticClass: "d-block",
    attrs: {
      "offset": _vm.offset,
      "cols": _vm.size
    }
  }, [_c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('div', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary",
      "rounded": "",
      "depressed": "",
      "loading": _vm.isSelecting
    },
    on: {
      "click": _vm.onButtonClick
    }
  }, [_vm.anexo.name ? _c('div', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-close-circle-outline ")]), _vm._v(" " + _vm._s(_vm.anexo.name) + " ")], 1) : _c('div', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-cloud-upload ")]), _vm._v(" " + _vm._s(_vm.label) + " ")], 1)]), _c('input', {
    ref: "uploader",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": ".jpg, .jpeg, .png, .bmp, .pdf"
    },
    on: {
      "change": _vm.onFileChanged
    }
  })], 1)])]), _vm.showInfo ? _c('v-col', {
    staticClass: "d-block",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" Só poderá anexar arquivo no(s) formato(s): "), _c('strong', [_vm._v(_vm._s(_vm.formats))])]), _c('div', [_vm._v(" O tamanho do arquivo deverá ser no máximo de: "), _c('strong', [_vm._v(_vm._s(_vm.maxSize) + "MB")])]), _c('div', [_vm._v(" Só poderá ser anexado "), _c('strong', [_vm._v("01")]), _vm._v(" arquivo ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }