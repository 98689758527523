var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.disabled ? _c('div', {
    staticClass: "d-flex col",
    class: `col-${_vm.cols}`
  }, [_c('v-text-field', {
    staticClass: "pr-12",
    attrs: {
      "outlined": "",
      "dense": "",
      "readonly": "",
      "value": _vm.value.ano,
      "label": `Ano ${_vm.sufix}`
    }
  }), _c('v-text-field', {
    staticClass: "pl-12",
    attrs: {
      "outlined": "",
      "dense": "",
      "readonly": "",
      "value": _vm.value.semestre,
      "label": `Semestre ${_vm.sufix}`
    }
  })], 1) : _c('div', {
    staticClass: "d-flex col",
    class: `col-${_vm.cols}`
  }, [_c('v-select', {
    staticClass: "pr-12",
    attrs: {
      "outlined": "",
      "dense": "",
      "label": `Ano ${_vm.sufix}`,
      "items": _vm.form.lists.listAnos,
      "item-text": 'ano',
      "rules": _vm.required ? [function (v) {
        return _vm.$required(v);
      }] : []
    },
    model: {
      value: _vm.value.ano,
      callback: function ($$v) {
        _vm.$set(_vm.value, "ano", $$v);
      },
      expression: "value.ano"
    }
  }), _c('v-select', {
    staticClass: "pl-12",
    attrs: {
      "outlined": "",
      "dense": "",
      "label": `Semestre ${_vm.sufix}`,
      "items": _vm.form.lists.listSemestres,
      "item-text": 'ano',
      "rules": _vm.required ? [function (v) {
        return _vm.$required(v);
      }] : [],
      "disabled": _vm.value.ano == 'Não Clonar'
    },
    model: {
      value: _vm.value.semestre,
      callback: function ($$v) {
        _vm.$set(_vm.value, "semestre", $$v);
      },
      expression: "value.semestre"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }