import { AUTH_NAMESPACE, LOGIN, UPDATE_MENU, UPDATE_ABILITIES } from "store/modules/auth";
import { createNamespacedHelpers } from "vuex";
import { convertPermissionsIntoAbilities } from "commons/ability";

const { mapActions: authActions } = createNamespacedHelpers(AUTH_NAMESPACE);

const mixin = {
  methods: {
    ...authActions([LOGIN, UPDATE_MENU, UPDATE_ABILITIES]),
    $atualizarCacheMenu() {
      return this.$obterMenu(this.$user.username).then(({ data }) => {
        this[UPDATE_MENU](this.$getTransformedMenu(data.itens));
        return this.$obterPermissoesDoUsuario(this.$user.username).then(({ data }) => {
          return this[UPDATE_ABILITIES](convertPermissionsIntoAbilities(data.permissoes));
        });
      });
    },
    $getTransformedMenu(grupos) {
      if (grupos) {
        let menu = [];
        for (let grupo of grupos) {
          let menuItem = {
            icon: grupo.icone,
            name: grupo.nome,
            url: grupo.endereco,
            items: [],
          };
          if (Array.isArray(grupo.itens)) {
            for (let item of grupo.itens) {
              menuItem.items.push({
                icon: item.icone,
                name: item.nome,
                url: item.endereco,
              });
            }
            menu.push(menuItem);
          }
        }
        return menu;
      }
      return [];
    },
  },
};

export default mixin;
