const baseURL = "/v1/FaixaPagamento";

const service = {
  methods: {
    $buscarFaixasDePagamento(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $obterDadosFaixaDePagamento({params, id}) {
      return this.$api().get(`${baseURL}/${id}`, params);
    },
    $cadastrarFaixaDePagamento(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarFaixaDePagamento(body, id) {
      return this.$api().put(`${baseURL}/${id}`, body);
    },
    $inativarAtivarFaixaDePagamento(body, id) {
      return this.$api().patch(`${baseURL}/${id}`, body);
    }
  },
};

export default service;
