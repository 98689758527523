var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip', {
    attrs: {
      "color": _vm.ativo ? 'green' : 'red',
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.ativo ? "Sim" : "Não") + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }