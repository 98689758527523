<template>
  <div class="d-flex col" :class="`col-${cols}`">
    <v-select
      label="Ativo"
      :items="[
        { label: 'Sim', value: true },
        { label: 'Não', value: false },
      ]"
      :item-text="'label'"
      :item-value="'value'"
      v-model="value"
      outlined
      dense
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "AtivoComponent",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 12,
    },
  },
  watch: {
    value(v) {
      this.$emit("input", v);
    },
  },
};
</script>