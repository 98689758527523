var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex col",
    class: `col-${_vm.cols}`
  }, [_c('v-select', {
    attrs: {
      "label": "Ativo",
      "items": [{
        label: 'Sim',
        value: true
      }, {
        label: 'Não',
        value: false
      }],
      "item-text": 'label',
      "item-value": 'value',
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }