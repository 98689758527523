<template>
  <v-col cols="12" :sm="cols">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          readonly
          outlined
          dense
          v-model="computedTimeFormattedCompleted"
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          :append-icon="removeIcon ? '' : 'mdi-timer'"
          :label="label"
          :horaEdicao="horaEdicao"
          :rules="required ? [(v) => $required(v)] : []"
        ></v-text-field>
      </template>
      <v-time-picker
        format="24hr"
        v-model="value"
        @input="menu = false"
      >
      </v-time-picker>
    </v-menu>
  </v-col>
</template>

<script>
export default {
  name: "TimePickerComponent",
  props: {
    label: {
      type: String,
    },
    horaEdicao: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    removeIcon: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: [Number, String],
      default: 6,
    },
  },
  computed: {
    computedTimeFormattedCompleted() {
      return this.horaEdicao || this.value;
    }
  },
  data: () => ({
    menu: false,
    value: null,
  }),
  watch: {
    value(val) {
      this.$emit("input", val);
    },
  },
};
</script>
