var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex col",
    class: `col-${_vm.cols}`
  }, [_c('v-autocomplete', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": _vm.label,
      "items": _vm.form.lists.lotacoes,
      "item-text": 'nome',
      "item-value": 'codigoLotacao',
      "rules": _vm.required ? [function (v) {
        return _vm.$required(v);
      }] : []
    },
    model: {
      value: _vm.value.codigoLotacao,
      callback: function ($$v) {
        _vm.$set(_vm.value, "codigoLotacao", $$v);
      },
      expression: "value.codigoLotacao"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }