<template>
  <div class="text-center">
    <v-dialog persistent v-model="visible" width="100%">
      <v-card class="not-margin pt-0 pr-0 pl-0">
        <v-toolbar dark color="primary">
          <div class="text-center">{{ label }}</div>
        </v-toolbar>
        <div class="container dialog">
          <v-form ref="form">
            <v-row class="d-flex justify-center mt-7"> </v-row>
          </v-form>
          <v-row class="text-center">
            <v-col cols="12">
              <iframe
                id="framePreview"
                v-if="previewUrl"
                style="border: 1px solid #666ccc"
                title="PDF in an i-Frame"
                :src="previewUrl"
                frameborder="1"
                scrolling="auto"
                width="100%"
                height="600px"
              ></iframe>
              <p v-else>
                Não foi possível carregar a pré-visualização do anexo
              </p>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12" class="d-block">
              <v-btn color="primary" outlined v-download="path">
                {{ `${descricao}` }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="text-center">
          <v-btn color="primary" @click="close()">Fechar</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PreviewFileComponent",
  props: {
    label: {
      type: String,
      default: "Pré visualização do anexo",
    },
    path: {
      type: String,
      default: "",
    },
    descricao: {
      type: String,
      default: "",
    },
    offset: {
      type: Number,
    },
    visible: {
      default: false,
    },
    previewUrl: {
      default: undefined,
    },
  },
  watch: {},
  methods: {
    close() {
      var frame = document.getElementById("framePreview");
      var frameDoc = frame.contentDocument || frame.contentWindow.document;
      frameDoc.documentElement.innerHTML = "";
      this.previewUrl = undefined;
      this.$emit("onClose");
    },
  },
  mounted() {
    if (typeof this.previewUrl === "string") {
      this.previewUrl = undefined;
    }
  },
};
</script>
<style scoped>
.v-icon--left {
  margin-right: 8px;
}
</style>