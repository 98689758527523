<template>
  <div class="d-flex col" :class="`col-${cols}`">
    <v-autocomplete
      v-model="value.codigoLotacao"
      outlined
      dense
      :label="label"
      :items="form.lists.lotacoes"
      :item-text="'nome'"
      :item-value="'codigoLotacao'"
      :rules="required ? [(v) => $required(v)] : []"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "SelectLotacaoComponent",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    // Flag que define qual deve ser a consulta de lotações
    relatorio: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Lotação",
    },
    cols: {
      type: Number,
      default: 12,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      lists: {
        lotacoes: [],
      },
    },
  }),
  watch: {
    value(v) {
      this.$emit("input", v);
    },
  },
  mounted() {
    const consultarLotacoes = (rel) => {
      return rel
        ? this.$listarTodasLotacoesRelatorio()
        : this.$listarTodasLotacoesParaValidar()
    }
    consultarLotacoes(this.relatorio).then(({ data }) => {
      this.form.lists.lotacoes = data.lotacoes;
    });
  },
};
</script>
