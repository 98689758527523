import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isToday from "dayjs/plugin/isToday";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import customParseFormat  from "dayjs/plugin/customParseFormat";

dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);

export default {
  install(Vue, options = {}) {
    dayjs.locale(options.locale || "pt-br");
    Vue.prototype.$dayjs = dayjs;
  },
};
