const baseURL = "/v1/EixoTematico";

const service = {
  methods: {
    $buscarEixoTematico(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $obterDadosEixoTematico({ id, ano, semestre, params }) {
      return this.$api().get(`${baseURL}/${id}/${ano}/${semestre}`, params);
    },
    $cadastrarEixoTematico(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarEixoTematico(body, id) {
      return this.$api().put(`${baseURL}/${id}`, body);
    },
    $inativarAtivarEixoTematico(body, id) {
      return this.$api().patch(`${baseURL}/${id}`, body);
    },
  },
};

export default service;
