const mixin = {
  methods: {
    $notifySuccess(content) {
      this.notify({ content: content, color: "success" });
    },
    $notifyError(content) {
      this.notify({ content: content, color: "error" });
    },
    $notifyInfo(content) {
      this.notify({ content: content, color: "info" });
    },
    $notifyWarning(content) {
      this.notify({ content: content, color: "warning", light: true });
    },
    notify(options = undefined) {
      if (typeof options === "string") {
        options = { content: options };
      }

      if (options) {
        this.$root.notification.timeout = options.timeout;
        this.$root.notification.color = options.color;
        this.$root.notification.light = options.light;
        this.$root.notification.centered = options.centered;
        this.$root.notification.absolute = options.absolute;
        this.$root.notification.rounded = options.rounded;

        this.$root.notification.top = !options.bottom; // padrão é top
        this.$root.notification.right = !options.left; // padrão é right
        this.$root.notification.bottom = options.bottom;
        this.$root.notification.left = options.left;

        this.$root.notification.closable =
          typeof options.closable === "boolean" ? options.closable : true;
        this.$root.notification.content = options.content;
      }

      this.$root.notification.visible = true;
    },
    hideNotification() {
      this.$root.notification.visible = false;
    },
  },
};

export default mixin;
