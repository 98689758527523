<template>
  <v-row>
    <v-col class="py-0">
      <v-breadcrumbs
        class="pa-5"
        :items="breadcrumbItens(vueInstance)"
      ></v-breadcrumbs>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BreadcrumbsComponent",
  data: () => ({
    vueInstance: null,
  }),
  methods: {
    breadcrumbItens: (vueInstance) => {
      let items = [];
      vueInstance.$route.matched.forEach((route) => {
        if (route.meta.breadcrumb) {
          route.meta.breadcrumb.split("/").forEach((bread) => {
            items.push({
              text: bread,
              disabled: true,
            });
          });
        } else {
          items.push({
            text: route.meta.title,
            to: route.path,
            disabled: true,
          });
        }
      });
      return items;
    },
  },
  created() {
    this.vueInstance = this;
  },
};
</script>