<template>
    <div v-if="disabled" class="d-flex col" :class="`col-${cols}`">
      <v-text-field
        class="pr-12"
        outlined
        dense
        readonly
        :value="value.ano"
        :label="`Ano ${sufix}`"
      ></v-text-field>
      <v-text-field
        class="pl-12"
        outlined
        dense
        readonly
        :value="value.semestre"
        :label="`Semestre ${sufix}`"
      ></v-text-field>
    </div>
    <div v-else class="d-flex col" :class="`col-${cols}`">
      <v-select
        v-model="value.ano"
        class="pr-12"
        outlined
        dense
        :label="`Ano ${sufix}`"
        :items="form.lists.listAnos"
        :item-text="'ano'"
        :rules="required ? [(v) => $required(v)] : []"
      ></v-select>
      <v-select
        v-model="value.semestre"
        class="pl-12"
        outlined
        dense
        :label="`Semestre ${sufix}`"
        :items="form.lists.listSemestres"
        :item-text="'ano'"
        :rules="required ? [(v) => $required(v)] : []"
        :disabled="value.ano == 'Não Clonar'"
      ></v-select>
    </div>
  </template>
  
  <script>
  export default {
    name: "SelectAnoSemestreComponent",
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
      // Label a ser mostrado após "Ano" e "Semestre"
      sufix: {
        type: String,
        default: "",
      },
      // Flag para definir se a listagem vai possuir a opção "Não Clonar"
      noClone: {
        type: Boolean,
        default: false,
      },
      // Flag para definir se o select vai vir setado o ano e semestre ativo
      active: {
        type: Boolean,
        default: true,
      },
      // Flag para definir, se o select vai consultar apenas os anos/semestres ativos
      onlyActive: {
        type: Boolean,
        default: false,
      },
      cols: {
        type: Number,
        default: 12,
      },
      required: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      tipo: {
        type: Number,
        default: null
      }
    },
    data: () => ({
      ciclo: [],
      form: {
        lists: {
          listAnos: [],
          listSemestres: [],
        },
      },
    }),
    watch: {
  value(v) {
    this.$emit("input", v);
  },
  "value.ano": {
    handler(val) {
      this.form.lists.listSemestres = [];
 
      if (val !== "Não Clonar") {
        const semestres = this.form.lists.listAnos
          .filter((l) => l.ano == val)
          .map((l) => l.semestre)
          .flat();
 
        // Define o semestre como o primeiro da lista (ou o único) se houver algum disponível
        if (semestres.length > 0) {
          this.form.lists.listSemestres = [...semestres];
          this.value.semestre = this.form.lists.listSemestres[0];
        } else {
          // Se não houver semestres disponíveis, define como vazio ou alguma lógica apropriada
          this.value.semestre = "";
        }
      } else {
        // Se o ano for "Não Clonar", define o semestre como vazio ou alguma lógica apropriada
        this.value.semestre = "";
      }
 
      // Adiciona "Não Clonar" à lista de semestres apenas se o ano for "Não Clonar"
      if (this.noClone && val == "Não Clonar") {
        this.form.lists.listSemestres.push("Não Clonar");
        this.value.semestre = "Não Clonar";
      }
    },
  },
},
    mounted() {
    this.$listarAnoSemestreEdicoes({ params: { tipo: this.tipo } }).then(({ data }) => {
        this.form.lists.listAnos = data.anosSemestres;
        this.value.ano = this.value.ano ? parseInt(this.value.ano) : "Não Clonar";
        this.value.semestre = this.value.semestre ? parseInt(this.value.semestre) : "Não Clonar";
        this.form.lists.listSemestres = this.form.lists.listAnos
            .filter((l) => l.ano == this.value.ano)
            .map((l) => l.semestre)
            .flat();
 
        if (this.noClone) this.form.lists.listAnos.push("Não Clonar");
    });
},
  };
  </script>
  <style lang="scss" scoped>
  div.col {
    .v-input.pr-12 {
      padding-right: 12px !important;
      width: 50%;
    }
    .v-input.pl-12 {
      padding-left: 12px !important;
      width: 50%;
    }
  }
  </style>
  