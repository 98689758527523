const baseURL = "/v1/AcaoCapacitacao";

const service = {
  methods: {
    $cadastrarComprovacaoAcaoCapacitacao(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarComprovacaoAcaoCapacitacao(body) {
      return this.$api().put(`${baseURL}`, body);
    },
    $obterDadosComprovacaoAcaoCapacitacao(params) {
      return this.$api().get(`${baseURL}/Comprovacao`, params);
    },
    $listarInstituicoesAcaoCapacitacao(params) {
      return this.$api().get(`${baseURL}/Instituicao`, params);
    },
    $listarTiposAcaoCapacitacao(params) {
      return this.$api().get(`${baseURL}/TiposCapacitacao`, params);
    },
    $listarEixosTematicosAcaoCapacitacao(params) {
      return this.$api().get(`${baseURL}/EixoTematico`, params);
    },
    $listarTrilhaAprendizagemAcaoCapacitacao(params) {
      return this.$api().get(`${baseURL}/TrilhaDeAprendizagem`, params);
    },
    $listarComprovacaoSituacao(params) {
      return this.$api().get(`${baseURL}/ListarComprovacaoSituacao`, params);
    },
    $listarAnoSemestreEdicoes(params) {
      return this.$api().get(`${baseURL}/AnosSemestresEdicoes`, params);
    },
    $listarHistoricoComprovacoes(nomeDeUsuario, params) {
      return this.$api().get(`${baseURL}/${nomeDeUsuario}/ListarHistoricoComprovacoes`, params);
    },
    $listarComprovacoesPorUsuario(nomeDeUsuario, params) {
      return this.$api().get(`${baseURL}/${nomeDeUsuario}/ListarComprovacoes`, params);
    },
    $listarMatriculasPorUsuario(nomeDeUsuario, params) {
      return this.$api().get(`${baseURL}/${nomeDeUsuario}/ListarMatriculas`, params);
    },
    $listarAnoSemestrePorUsuario(nomeDeUsuario, params) {
      return this.$api().get(`${baseURL}/${nomeDeUsuario}/AnoSemestre`, params);
    },
    $realizarDownloadPorPath(params) {
      return this.$api().get(`${baseURL}/Download`, params);
    },
    $listarAnoSemestreEdicoesCiclos(params) {
      return this.$api().get(`${baseURL}/AnosSemestresEdicoes`, params);
    },
  },
};

export default service;
