const filter = (value, dateTime) => {
  const returnDateOrDateTime = () => {
    return dateTime
      ? new Date(value).toLocaleString()
      : new Date(value).toLocaleDateString()
  }
  return value
    ? returnDateOrDateTime()
    : "Sem data final";
}

export default filter;
