var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', [_c('v-row', {
    staticClass: "d-block text-center btn-group"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": _vm.disableAction
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm.actionIcon ? _c('v-icon', [_vm._v(_vm._s(_vm.actionIcon))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.actionText) + " ")], 1), _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": _vm.disableCancel,
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm.cancelIcon ? _c('v-icon', [_vm._v(_vm._s(_vm.cancelIcon))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.cancelText) + " ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }