const baseURL = "/v1/VerbaPagamento";

const service = {
  methods: {
    $buscarVerbaPagamento(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $obterDadosVerbaPagamento({id, params}) {
      return this.$api().get(`${baseURL}/${id}`, params);
    },
    $cadastrarVerbaPagamento(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarVerbaPagamento(body, id) {
      return this.$api().put(`${baseURL}/${id}`, body);
    },
    $inativarAtivarVerbaPagamento(body, id) {
      return this.$api().patch(`${baseURL}/${id}`, body);
    },
  },
};

export default service;
