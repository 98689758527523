const baseURL = "/v1/Inicio";

const service = {
  methods: {
    $obterPerguntasFaq(params) {
      return this.$api().get(`${baseURL}/Faq`, params);
    },
    $obterConteudoSecoes(params) {
      return this.$api().get(`${baseURL}/Secoes`, params);
    },
    $salvarConteudoSecoes(body) {
      return this.$api().put(`${baseURL}/Secoes`, body);
    },
  },
};

export default service;
