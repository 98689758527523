var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_vm.small ? _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "disabled": _vm.disabled
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('click');
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": _vm.color
          }
        }, [_vm._v(" " + _vm._s(_vm.icon) + " ")])], 1) : _c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-4",
          attrs: {
            "color": _vm.color,
            "disabled": _vm.disabled
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('click');
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(_vm._s(_vm.icon))])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.text))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }