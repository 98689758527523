var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-breadcrumbs', {
    staticClass: "pa-5",
    attrs: {
      "items": _vm.breadcrumbItens(_vm.vueInstance)
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }