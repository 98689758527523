const baseURL = "/v1/TipoAcaoCapacitacao";

const service = {
  methods: {
    $buscarTiposAcaoCapacitacao(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $obterDadosTipoAcaoCapacitacao({ params, id }) {
      return this.$api().get(`${baseURL}/${id}`, params);
    },
    $cadastrarTipoAcaoCapacitacao(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarTipoAcaoCapacitacao(body, id) {
      return this.$api().put(`${baseURL}/${id}`, body);
    },
    $inativarAtivarTipoAcaoCapacitacao(body, id) {
      return this.$api().patch(`${baseURL}/${id}`, body);
    }
  },
};

export default service;
