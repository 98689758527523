const baseURL = "/v1/Faq";

const service = {
  methods: {
    $buscarDadosFAQ(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $visualizarDadosFAQ({ params, id }) {
      return this.$api().get(`${baseURL}/${id}`, params);
    },
    $cadastrarPerguntaFaq(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarPerguntaFaq(body, id) {
      return this.$api().put(`${baseURL}/${id}`, body);
    },
    $inativarAtivarPerguntaFaq(body, id) {
      return this.$api().patch(`${baseURL}/${id}`, body);
    }
  },
};

export default service;
