const baseURL = "/v1/AcaoComprovacao";

const service = {
  methods: {
    $verificarPeriodoValidoParaValidar(params) {
      return this.$api().get(`${baseURL}/Periodo`, params);
    },
    $listarTodasLotacoesParaValidar(params) {
      return this.$api().get(`${baseURL}/Lotacao`, params);
    },
    $listarTodasLotacoesRelatorio(params) {
      return this.$api().get(`${baseURL}/LotacaoRelatorio`, params);
    },
    $buscarComprovacoesParaValidar(params) {
      return this.$api().get(`${baseURL}/Comprovacao`, params);
    },
    $listarEixosTematicosAcaoComprovacao(params) {
      return this.$api().get(`${baseURL}/ListarEixoTematico`, params);
    },
    $listarSituacaoComprovacaoParaValidar(params) {
      return this.$api().get(`${baseURL}/ListarComprovacaoSituacao`, params);
    },
    $listarAnoSemestreComprovacaoParaValidar(params) {
      return this.$api().get(`${baseURL}/ListarAnoSemestre`, params);
    },
    $obterTodasTrilhasDeAprendizagem(params) {
      return this.$api().get(`${baseURL}/ListarTrilhaAprendizagem`, params);
    },
    $visualizarDadosComprovacaoParaValidar(params) {
      return this.$api().get(`${baseURL}/VisualizarComprovacao`, params);
    },
    $listarMotivosParaValidar(params) {
      return this.$api().get(`${baseURL}/ListarMotivo`, params);
    },
    $validarComprovacao(id, body) {
      return this.$api().patch(`${baseURL}/Validacao/${id}`, body);
    },
  },
};

export default service;
