const baseURL = "/v1/TrilhaAprendizagem";

const service = {
  methods: {
    $buscarTrilhasDeAprendizagem(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $obterDadosTrilhaDeAprendizagem({id, ano, semestre, params}) {
      return this.$api().get(`${baseURL}/${id}/${ano}/${semestre}`, params);
    },
    $cadastrarTrilhaDeAprendizagem(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarTrilhaDeAprendizagem(body, id) {
      return this.$api().put(`${baseURL}/${id}`, body);
    },
    $inativarAtivarTrilhaDeAprendizagem(body, id) {
      return this.$api().patch(`${baseURL}/${id}`, body);
    },
  },
};

export default service;
