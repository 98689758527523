<template>
  <div class="d-flex col" :class="`col-${cols}`">
    <v-select
      v-model="value.situacao"
      outlined
      dense
      :label="label"
      :items="situacoes"
      :item-text="'descricao'"
      :item-value="'id'"
      :rules="required ? [(v) => $required(v)] : []"
    ></v-select>
  </div>
</template>

<script>
// Id das Situações
// 1 - Comprovação da Ação de Capacitação Não Validada
// 2 - Comprovação da Ação de Capacitação Validada
// 3 - Comprovação da Ação de Capacitação Pendente de validação
export default {
  name: "SelectLotacaoComponent",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    // Lista para informar o id caso deseje excluir alguma situação da listagem
    exclude: {
      type: Array,
      default: () => ([]),
    },
    // Flag para informar o id caso deseje que já venha uma situação setada
    preset: {
      type: Number,
      default: null,
    },
    // Flag para definir se a listagem vai possuir a opção "Todos"
    all: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Situação",
    },
    cols: {
      type: Number,
      default: 12,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    situacoes() {
      return this.exclude.length
        ? this.form.lists.situacoes.filter(s => !this.exclude.includes(s.id))
        : this.form.lists.situacoes
    }
  },
  data: () => ({
    form: {
      lists: {
        situacoes: [],
      },
    },
  }),
  watch: {
    value(v) {
      this.$emit("input", v);
    },
  },
  mounted() {
    this.$listarSituacaoComprovacaoParaValidar().then(({ data }) => {
      this.form.lists.situacoes = data.situacoes;
      this.all && this.form.lists.situacoes.push("Todas")
      this.preset && (this.form.filters.situacao = this.preset);
    });
  },
};
</script>
