<template>
  <v-row class="text-center">
    <v-col :offset="offset" :cols="size" class="d-block">
      <div id="app">
        <div>
          <v-btn
            color="primary"
            class="text-none"
            rounded
            depressed
            :loading="isSelecting"
            @click="onButtonClick"
          >
            <div v-if="anexo.name">
              <v-icon left> mdi-close-circle-outline </v-icon>
              {{ anexo.name }}
            </div>
            <div v-else>
              <v-icon left> mdi-cloud-upload </v-icon>
              {{ label }}
            </div>
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept=".jpg, .jpeg, .png, .bmp, .pdf"
            @change="onFileChanged"
          />
        </div>
      </div>
    </v-col>
    <v-col v-if="showInfo" cols="12" class="d-block">
      <div>
        Só poderá anexar arquivo no(s) formato(s):
        <strong>{{ formats }}</strong>
      </div>
      <div>
        O tamanho do arquivo deverá ser no máximo de:
        <strong>{{ maxSize }}MB</strong>
      </div>
      <div>
        Só poderá ser anexado
        <strong>01</strong> arquivo
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "UploadFileComponent",
  props: {
    label: {
      type: String,
      default: "Envie um arquivo",
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
    formats: {
      type: String,
      default: "JPEG, JPG, PNG, BMP e PDF",
    },
    maxSize: {
      type: Number,
      default: 2,
    },
    size: {
      type: Number,
      default: 12,
    },
    offset: {
      type: Number,
    },
    anexo: {
      default: null,
    },
  },
  data: () => ({
    isSelecting: false,
  }),
  watch: {
    anexo(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.anexo = e.target.files[0];
    },
  },
};
</script>
<style scoped>
.v-icon--left {
  margin-right: 8px;
}
</style>