var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('VueRecaptcha', {
    ref: "recaptcha",
    attrs: {
      "sitekey": _vm.sitekey,
      "loadRecaptchaScript": true
    },
    on: {
      "verify": _vm.onCaptchaVerified,
      "expired": _vm.onCaptchaExpired
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }