<template>
  <VueRecaptcha
    :sitekey="sitekey"
    :loadRecaptchaScript="true"
    ref="recaptcha"
    @verify="onCaptchaVerified"
    @expired="onCaptchaExpired"
  >
  </VueRecaptcha>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import configurations from "commons/configurations";

export default {
  name: "RecaptchaComponent",
  components: { VueRecaptcha },
  data: () => ({
    verified: false,
    sitekey: configurations.env.sitekey,
  }),
  methods: {
    onCaptchaVerified(response) {
      if (response) {
        this.verified = true;
        this.$emit("recaptchaVerified", this.verified);
      }
    },
    onCaptchaExpired() {
      this.verified = false;
      this.$refs.recaptcha.reset();
      this.$emit("recaptchaVerified", this.verified);
    },
  },
};
</script>