<template>
  <v-app id="app-wrapper">
    <div v-if="displayBreakpoint" id="breakpoint">
      {{ $vuetify.breakpoint.name }}
    </div>
    <vue-page-transition name="fade-in-right">
      <router-view class="layout-root" />
    </vue-page-transition>

    <v-snackbar
      v-model="$root.notification.visible"
      v-bind="$root.notification"
      :class="{ 'snack__action--hidden': hideNotificationButton }"
    >
      <div v-html="$root.notification.content"></div>
      <template v-if="$root.notification.closable" #action="{ attrs }">
        <v-btn
          :light="$root.notification.light"
          text
          v-bind="attrs"
          @click="closeNotification()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <div class="d-none">
      Versão do sistema: {{ $configurations.app.version }}
    </div>

    <PageLoaderComponent v-model="$root.loading.visible"></PageLoaderComponent>
    <AppUpdaterComponent :app-name="$appName"></AppUpdaterComponent>
  </v-app>
</template>

<script>
import AppUpdaterComponent from "./components/AppUpdaterComponent.vue";
import PageLoaderComponent from "~/components/PageLoaderComponent.vue";
import {
  createInterceptorError,
  createInterceptorLoading,
  createInterceptorToken,
} from "plugins/axios-plugin";

export default {
  name: "App",
  components: { PageLoaderComponent, AppUpdaterComponent },
  computed: {
    hideNotificationButton() {
      return this.$root.notification.closable ? false : true;
    },
    displayBreakpoint() {
      return this.$configurations.env.nodeEnv !== "production";
    },
  },
  created() {
    createInterceptorError(this);
    createInterceptorLoading(this);
    createInterceptorToken(this);
  },
  methods: {
    closeNotification() {
      this.$root.notification.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-snack.snack__action--hidden) {
  .v-snack__action {
    display: none;
  }
}

#breakpoint {
  background: yellow;
  border: 1px solid red;
  color: red;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding: 1px 5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999;
}
</style>