const baseURL = "/v1/Acesso";

const service = {
  methods: {
    $realizarLogin(body) {
      return this.$api().post(`${baseURL}/Autenticar`, body);
    },
    $obterMenu(nomeDeUsuario) {
      return this.$api().get(`${baseURL}/Usuario/${encodeURI(nomeDeUsuario)}/Menu`);
    },
    $obterPermissoesDoUsuario(nomeDeUsuario) {
      return this.$api().get(`${baseURL}/Usuario/${encodeURI(nomeDeUsuario)}/Permissoes`);
    },
    $solicitarTrocaDeSenha(body) {
      return this.$api().post(`${baseURL}/SolicitarTrocaDeSenha`, body);
    },
    $mudarSenha(body) {
      return this.$api().post(`${baseURL}/MudarSenha`, body);
    },
    $listarGrupos(params) {
      return this.$api().get(`${baseURL}/Grupos`, params);
    },
    $listarPerfis(params) {
      return this.$api().get(`${baseURL}/Perfis`, params);
    },
    $criarGrupo(body) {
      return this.$api().post(`${baseURL}/Grupos`, body);
    },
    $deletarGrupo(idDoGrupo) {
      return this.$api().delete(`${baseURL}/Grupos/${idDoGrupo}`);
    },
    $atualizarGrupo(idDoGrupo, body) {
      return this.$api().patch(`${baseURL}/Grupos/${idDoGrupo}`, body);
    },
    $listarPermissoes(params) {
      return this.$api().get(`${baseURL}/Permissoes`, params);
    },
    $criarPermissao(body) {
      return this.$api().post(`${baseURL}/Permissoes`, body);
    },
    $deletarPermissao(idDaPermissao) {
      return this.$api().delete(`${baseURL}/Permissoes/${idDaPermissao}`);
    },
    $atualizarPermissao(idDaPermissao, body) {
      return this.$api().patch(`${baseURL}/Permissoes/${idDaPermissao}`, body);
    },
    $atualizarPermissaoDoGrupo(idDoGrupo, idDaPermissao, body) {
      return this.$api().patch(`${baseURL}/Grupos/${idDoGrupo}/Permissao/${idDaPermissao}`, body);
    },
  },
};

export default service;
