const baseURL = "/v1/Grupo";

const service = {
  methods: {
    $buscarGrupos(params) {
      return this.$api().get(`${baseURL}`, params);
    },
    $buscarTodasPermissoes(params) {
      return this.$api().get(`${baseURL}/Permissoes`, params);
    },
    $visualizarGrupos({ params, id }) {
      return this.$api().get(`${baseURL}/${id}`, params);
    },
    $cadastrarGrupo(body) {
      return this.$api().post(`${baseURL}`, body);
    },
    $editarGrupo(body, id) {
      return this.$api().put(`${baseURL}/${id}`, body);
    },
    $inativarAtivarGrupo(body, id) {
      return this.$api().patch(`${baseURL}/${id}`, body);
    }
  },
};

export default service;
