// Vue Core
import Vue from "vue";
import VuePageTransition from "vue-page-transition";
import VueGtag from "vue-gtag";
import VueSanitize from "vue-sanitize";
import App from "./App.vue";

import {
  PRE_LOGIN_LAYOUT_NAMESPACE,
  UPDATE_BACKGROUND_IMAGE_VISIBILITY,
} from "store/modules/pre-login-layout";
import { AUTH_NAMESPACE, REFRESH_TOKEN } from "store/modules/auth";

import "@mdi/font/css/materialdesignicons.css";
import "animate.css/animate.css";
import "assets/styles/app.scss";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "./registerServiceWorker";

import { AxiosPlugin } from "plugins/axios-plugin";
import vuetify from "plugins/vuetify-plugin";
import dayJsPlugin from "plugins/dayjs-plugin";
import PrimeVue from "primevue/config";

import routerFactory from "router";
import store from "store";
import configurations from "commons/configurations";
import moment from "moment";

import { VueMaskDirective } from "v-mask";
import DownloadFileDirective from "~/directives/download.directive";
import VueSweetAlert from "vue-sweetalert";

import ButtonsComponent from "~/components/ButtonsComponent.vue";
import RecaptchaComponent from "~/components/RecaptchaComponent.vue";
import BreadcrumbsComponent from "~/components/BreadcrumbsComponent.vue";
import TooltipComponent from "~/components/TooltipComponent.vue";
import UploadFileComponent from "~/components/UploadFileComponent.vue";
import PreviewFileComponent from "~/components/PreviewFileComponent.vue";
import DatePickerComponent from "~/components/DatePickerComponent.vue";
import TimePickerComponent from "~/components/TimePickerComponent.vue";
import AtivoSelectComponent from "~/components/AtivoSelectComponent.vue";
import AtivoLabelComponent from "~/components/AtivoLabelComponent.vue";
import SelectAnoSemestreComponent from "~/components/SelectAnoSemestreComponent.vue";
import SelectAnoSemestreCicloComponent from "~/components/SelectAnoSemestreCicloComponent.vue";
import SelectLotacaoComponent from "~/components/SelectLotacaoComponent.vue";
import SelectSituacaoComponent from "~/components/SelectSituacaoComponent.vue";
import SwitchButtonComponent from "~/components/SwitchButtonComponent.vue";
import EditorHTMLComponent from "~/components/EditorHTMLComponent.vue";

import PageLoadingMixin from "~/mixins/page-loading.mixin";
import NotificationMixin from "~/mixins/notification.mixin";
import ThemeMixin from "~/mixins/theme.mixin";
import ValidationsMixin from "~/mixins/validations.mixin";
import UtilMixin from "~/mixins/util.mixin";
import MenuMixin from "~/mixins/menu.mixin";

import CapitalizeFilter from "~/filters/capitalize.filter";
import CurrencyFilter from "~/filters/currency.filter";
import DateFilter from "~/filters/date.filter";

import LandingPageService from "~/services/landing-page.service";
import AuthService from "~/services/auth.service";
import ProfissionalService from "~/services/profissional.service";
import TermoService from "~/services/termo.service";
import AcaoCapacitacaoService from "~/services/acao-capacitacao.service";
import AcaoComprovacaoService from "~/services/acao-comprovacao.service";
import RelatorioPagamentoService from "~/services/relatorios-admin.service";
import ManterFAQService from "~/services/manter-faq.service";
import ManterInstituicaoService from "~/services/manter-instituicao.service";
import ManterTiposDeAcaoCapacitacaoService from "~/services/manter-tipo-acao-capacitacao.service";
import ManterCicloService from "~/services/manter-ciclo.service";
import ManterParametrosComprovacaoService from "~/services/manter-parametros-comprovacao.service";
import ManterTrilhaDeAprendizagemService from "~/services/manter-trilha-de-aprendizagem.service";
import ManterEixoTematicoService from "~/services/manter-eixo-tematico.service";
import ManterGrupoService from "~/services/manter-grupo.service";
import ManterUsuarioService from "~/services/manter-usuario.service";
import ManterMotivoService from "~/services/manter-motivo.service";
import ManterFaixaDePagamentoService from "~/services/manter-faixa-pagamento.service";
import ManterVerbaPagamentoService from "~/services/manter-verba-pagamento.service";
import ManterPerfilService from "~/services/manter-perfil.service";
import ManterTermoService from "~/services/manter-termo.service";

Vue.config.productionTip = false;
Vue.prototype.$configurations = configurations;
Vue.prototype.$appName = configurations.app.name;
Vue.prototype.$moment = moment;

// Generic Mixins
Vue.mixin(PageLoadingMixin);
Vue.mixin(NotificationMixin);
Vue.mixin(ThemeMixin);
Vue.mixin(ValidationsMixin);
Vue.mixin(UtilMixin);
Vue.mixin(MenuMixin);

// Services
Vue.mixin(LandingPageService);
Vue.mixin(AuthService);
Vue.mixin(ProfissionalService);
Vue.mixin(TermoService);
Vue.mixin(AcaoCapacitacaoService);
Vue.mixin(AcaoComprovacaoService);
Vue.mixin(RelatorioPagamentoService);
Vue.mixin(ManterFAQService);
Vue.mixin(ManterInstituicaoService);
Vue.mixin(ManterTiposDeAcaoCapacitacaoService);
Vue.mixin(ManterCicloService);
Vue.mixin(ManterParametrosComprovacaoService);
Vue.mixin(ManterTrilhaDeAprendizagemService);
Vue.mixin(ManterEixoTematicoService);
Vue.mixin(ManterGrupoService);
Vue.mixin(ManterUsuarioService);
Vue.mixin(ManterMotivoService);
Vue.mixin(ManterFaixaDePagamentoService);
Vue.mixin(ManterVerbaPagamentoService);
Vue.mixin(ManterPerfilService);
Vue.mixin(ManterTermoService);

// Components
Vue.component("avp-buttons", ButtonsComponent);
Vue.component("avp-recaptcha", RecaptchaComponent);
Vue.component("avp-breadcrumbs", BreadcrumbsComponent);
Vue.component("avp-tooltip", TooltipComponent);
Vue.component("avp-upload-file", UploadFileComponent);
Vue.component("avp-date-picker", DatePickerComponent);
Vue.component("avp-time-picker", TimePickerComponent);
Vue.component("avp-select-ativo", AtivoSelectComponent);
Vue.component("avp-label-ativo", AtivoLabelComponent);
Vue.component("avp-select-ano-semestre", SelectAnoSemestreComponent);
Vue.component("avp-select-lotacao", SelectLotacaoComponent);
Vue.component("avp-select-situacao", SelectSituacaoComponent);
Vue.component("avp-switch-button", SwitchButtonComponent);
Vue.component("avp-editor-html", EditorHTMLComponent);
Vue.component("avp-preview-file", PreviewFileComponent);
Vue.component("avp-select-ano-semestre-ciclo", SelectAnoSemestreCicloComponent);

// Directives
Vue.directive("mask", VueMaskDirective);
Vue.directive("download", DownloadFileDirective);

// Plugins
Vue.use(VuePageTransition);
Vue.use(VueSanitize);
Vue.use(dayJsPlugin, { locale: "pt-br" });
Vue.use(AxiosPlugin);
Vue.use(VueSweetAlert);
Vue.use(PrimeVue);

// Filters
Vue.filter("capitalize", CapitalizeFilter);
Vue.filter("currency", CurrencyFilter);
Vue.filter("date", DateFilter);

const router = routerFactory(store);

if (configurations.google.id) {
  Vue.use(
    VueGtag,
    {
      config: { id: configurations.google.id },
      appName: configurations.app.name,
      pageTrackerScreenviewEnabled: true,
      pageTrackerUseFullPath: true,
    },
    router
  );
}

new Vue({
  data: () => ({
    loading: {
      visible: true,
      content: undefined,
      progress: undefined,
      opacity: undefined,
    },
    notification: {
      visible: undefined,
      timeout: undefined,
      color: undefined,
      light: undefined,
      centered: undefined,
      absolute: undefined,
      rounded: undefined,
      top: true,
      right: true,
      bottom: undefined,
      left: undefined,
      content: undefined,
      closable: true,
    },
  }),
  router: router,
  store: store,
  vuetify,
  mounted() {
    this.hidePageLoading();
    this.$store.dispatch(`${AUTH_NAMESPACE}/${REFRESH_TOKEN}`).catch(()=>{});
  },
  methods: {
    showPageLoading() {
      this.loading.visible = true;
    },
    hidePageLoading() {
      this.loading.visible = false;
    },
    showDefaultBackgroundImage() {
      this.$store.dispatch(
        `${PRE_LOGIN_LAYOUT_NAMESPACE}/${UPDATE_BACKGROUND_IMAGE_VISIBILITY}`,
        true
      );
    },
    hideDefaultBackgroundImage() {
      this.$store.dispatch(
        `${PRE_LOGIN_LAYOUT_NAMESPACE}/${UPDATE_BACKGROUND_IMAGE_VISIBILITY}`,
        false
      );
    },
  },
  render: (h) => h(App),
}).$mount("#app");
