const baseURL = "/v1/Profissional";

const service = {
  methods: {
    $obterDadosDoProfissional(cpfDoUsuario) {
      return this.$api().get(`${baseURL}/${cpfDoUsuario}/ObterDadosDoProfissional`);
    },
    $atualizarDadosDoProfissional(body) {
      return this.$api().put(`${baseURL}/AtualizarDados`, body);
    },
  },
};

export default service;
